<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{ 'Logo' | translate }}</label>
      <div appImageUpload [imageUploadModel]="nodeResolver.dataModel" [imageUploadModelAttr]="'logo'" [imageUploadId]="'logo'"></div>
    </div>
    <div class="form-group">
      <label id="project_name">{{ 'Project name' | translate }}</label>
      <input name="node.dataModel.name" class="form-control" [(ngModel)]="nodeResolver.dataModel.name" type="text" required />
    </div>
    <div class="form-group">
      <label>{{ 'Description' | translate }}</label>
      <div>
        <textarea name="node.dataModel.description" class="form-control" [(ngModel)]="nodeResolver.dataModel.description"></textarea>
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'Homepage title' | translate }}</label>
      <div>
        <textarea class="form-control" name="node.dataModel.header_title_homepage" [(ngModel)]="nodeResolver.dataModel.header_title_homepage"></textarea>
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'Presentation' | translate }}</label>
      <div>
        <textarea class="form-control" name="node.dataModel.presentation" [(ngModel)]="nodeResolver.dataModel.presentation"></textarea>
      </div>
    </div>
    @if (authenticationService.session?.role === 'admin') {
      <div class="form-group">
        <label>{{ 'Question to solicit possible whistleblowers' | translate }}</label>
        <div>
          <textarea name="node.dataModel.whistleblowing_question" class="form-control" [(ngModel)]="nodeResolver.dataModel.whistleblowing_question"></textarea>
        </div>
      </div>
    }
    @if (authenticationService.session?.role === 'admin') {
      <div class="form-group">
        <label>{{ 'Whistleblowing button' | translate }}</label>
        <input name="nodeResolver.dataModel.whistleblowing_button" class="form-control" [(ngModel)]="nodeResolver.dataModel.whistleblowing_button" type="text" />
      </div>
    }
    @if (nodeResolver.dataModel.root_tenant || ['default', 'demo'].indexOf(appDataService.public.node.mode) !== -1) {
      <div class="form-group">
        <label>{{ 'Disclaimer' | translate }}</label>
        <div>
          <textarea name="nodeResolver.dataModel.disclaimer_text" class="form-control" [(ngModel)]="nodeResolver.dataModel.disclaimer_text"></textarea>
        </div>
      </div>
    }
    <div class="form-group">
      <label>{{'Whistleblowing Policy'|translate}}</label>
      <input name="footer_whistleblowing_policy" class="form-control" [(ngModel)]="nodeResolver.dataModel.footer_whistleblowing_policy" [pattern]="Constants.secureLocalUrlRegexp" [ngClass]="{'is-invalid': contentForm.form.get('footer_whistleblowing_policy')?.errors?.['pattern']}" type="text" />
    </div>
    <div class="form-group">
      <label>{{'Privacy Policy'|translate}}</label>
      <input name="footer_privacy_policy" class="form-control" [(ngModel)]="nodeResolver.dataModel.footer_privacy_policy" [pattern]="Constants.secureLocalUrlRegexp" [ngClass]="{'is-invalid': contentForm.form.get('footer_privacy_policy')?.errors?.['pattern']}" type="text" />
    </div>
    @if (nodeResolver.dataModel.root_tenant || ['default', 'demo'].indexOf(appDataService.public.node.mode) !== -1) {
      <div class="form-group">
        <label>{{ 'Footer' | translate }}</label>
        <div>
          <textarea name="nodeResolver.dataModel.footer" class="form-control" [(ngModel)]="nodeResolver.dataModel.footer"></textarea>
        </div>
      </div>
    }
    <div class="form-group">
      <button class="btn btn-primary" id="save_settings" [disabled]="contentForm.invalid" (click)="updateNode()" type="submit">
        <i class="fa-solid fa-check"></i>
        <span>{{ 'Save' | translate }}</span>
      </button>
    </div>
  </div>
</div>
