@if (state === 'require_recovery_key') {
  <div>
    <div class="row">
      <div class="col-md-12">
        <label>{{'Enter your account recovery key to complete the password reset procedure'|translate}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-auto">
        <form name="form" autocomplete="off" novalidate>
          <div class="input-group">
            <input class="form-control recovery-key-input" name="secret" type="text" placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX" maxlength="64" size="64" [(ngModel)]="request.recovery_key" autofocus>
            <div class="input-group-btn">
              <button class="btn btn-primary" id="submit" (click)="submit()" type="submit">{{'Submit'|translate}}</button>
              <a class="btn" href="" (click)="utilsService.openSupportModal(appDataService)" data-toggle="tooltip" title="{{'Request support' | translate}}">
                <i class="fa-solid fa-life-ring"></i>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
}
@if (state === 'require_two_factor_authentication') {
  <div>
    <div class="row">
      <div class="col-md-auto">
        <div class="form-group">
          <label>{{'Enter the two factor authentication code'|translate}}</label>
          <input class="form-control" [(ngModel)]="request.auth_code" type="text" size="6" maxlength="6" autofocus required />
        </div>
        <div class="input-group">
          <button class="btn btn-primary" [disabled]="request.auth_code.length !== 6" (click)="submit()" type="submit">
            <span>{{'Proceed'|translate}}</span>
          </button>
          <a class="btn" href="" (click)="utilsService.openSupportModal(appDataService)" data-toggle="tooltip" title="{{'Request support' | translate}}">
            <i class="fa-solid fa-life-ring"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
}