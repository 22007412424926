<div id="TipPageReceiversInfoBox" class="card card-default" [attr.aria-expanded]="collapsed">
  <div class="card-header d-flex justify-content-between" (click)="toggleCollapse();utilsService.stopPropagation($event)">
    <span>{{'Recipients'|translate}}</span>
    <span class="">
      @if (!collapsed) {
        <i class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
      }
      @if (collapsed) {
        <i class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
      }
    </span>
  </div>
  @if (!collapsed) {
    <div class="card-body">
      @for (receiver of tipService.tip.receivers; track receiver) {
        <div>
          <div>{{tipService.tip.receivers_by_id[receiver.id].name}}</div>
        </div>
      }
    </div>
  }
</div>