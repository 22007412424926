@if ((['checkbox', 'fieldgroup', 'multichoice'].indexOf(field.type) === -1) && (['whistleblower_identity'].indexOf(field.template_id) === -1)) {
  <div id="{{fieldId}}" class="question">
    <label class="question-label" data-content="field.hint" data-toggle="tooltip" [title]="field.hint" for="{{fieldId + '-input-' + 0}}">
      @if (field.label) {
        <span class="title">{{field.label|translate}}</span>
      }
      @if (field.hint) {
        <i class="fa-solid fa-question-circle"></i>
      }
      @if (field.required) {
        <span class="text-danger sup" data-toggle="tooltip" title="{{'This field is mandatory' | translate}}">*</span>
      }
    </label>
    <div class="question-body">
      @if (field.description) {
        <markdown class="description" [data]="field.description | stripHtml"></markdown>
      }
      <div class="form-group">
        @for (entry of entries; track entry; let entryIndex = $index; let first = $first; let last = $last) {
          <div class="field-answer-entry" [ngClass]="{'fieldAnswerEntryFirst': first, 'fieldAnswerEntryLast': last}">
            @if (entries.length > 1) {
              <div class="field-answer-entry-label clearfix">
                <span class="float-start">
                  <label class="text-muted">
                    <span>#{{entryIndex + 1}}</span>
                  </label>
                </span>
                <span class="float-end">
                  <span class="text-danger" data-toggle="tooltip" title="{{'Remove' | translate}}" [attr.aria-label]="'Remove' | translate" (click)="utilsService.deleteFromList(entries, entry)">
                    <i class="fa-solid fa-times"></i>
                  </span>
                </span>
              </div>
            }
            <src-form-field-input [fileUploadUrl]="fileUploadUrl" (notifyFileUpload)="notifyFileUpload.emit()" [identity_provided]=identity_provided [uploads]="uploads" [fields]="fields" [step]="step" [answers]="answers" [fieldId]="fieldId" [displayErrors]="displayErrors" [entry]="entry" [entryIndex]="entryIndex" [fieldEntry]="fieldEntry" [submission]="submission" [field]="field" [index]="entryIndex"></src-form-field-input>
          </div>
        }
        @if (field.multi_entry && field.type === 'fieldgroup') {
          <div class="float-end">
            <button class="btn btn-outline-secondary" [attr.aria-label]="'Add' | translate" (click)="addAnswerEntry(entries)">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
@if ((['checkbox', 'fieldgroup', 'multichoice'].indexOf(field.type) !== -1) || (['whistleblower_identity'].indexOf(field.template_id) !== -1)) {
  <fieldset id="{{fieldId}}" class="question">
    @if (field.label) {
      <legend class="title">{{field.label|translate}}</legend>
    }
    @if (field.hint) {
      <i class="fa-solid fa-question-circle"></i>
    }
    @if (field.required) {
      <span class="text-danger sup" data-toggle="tooltip" title="{{'This field is mandatory' | translate}}">*</span>
    }
    <div class="question-body">
      @if (field.description) {
        <markdown class="description" [data]="field.description | stripHtml"></markdown>
      }
      <div class="form-group">
        @for (entry of entries; track entry; let entryIndex = $index; let first = $first; let last = $last) {
          <div class="field-answer-entry" [ngClass]="{'fieldAnswerEntryFirst': first, 'fieldAnswerEntryLast': last}">
            @if (entries.length > 1) {
              <div class="field-answer-entry-label clearfix">
                <span class="float-start">
                  <label class="text-muted">
                    <span>#{{entryIndex + 1}}</span>
                  </label>
                </span>
                <span class="float-end">
                  <span class="text-danger" data-toggle="tooltip" title="{{'Remove' | translate}}" [attr.aria-label]="'Remove' | translate" (click)="utilsService.deleteFromList(entries, entry)">
                    <i class="fa-solid fa-times"></i>
                  </span>
                </span>
              </div>
            }
            <src-form-field-input [fileUploadUrl]="fileUploadUrl" (notifyFileUpload)="notifyFileUpload.emit()" [identity_provided]=identity_provided [uploads]="uploads" [fields]="fields" [step]="step" [answers]="answers" [fieldId]="fieldId" [displayErrors]="displayErrors" [entry]="entry" [entryIndex]="entryIndex" [fieldEntry]="fieldEntry" [submission]="submission" [field]="field" [index]="entryIndex"></src-form-field-input>
          </div>
        }
        @if (field.multi_entry && field.type === 'fieldgroup') {
          <div class="float-end">
            <button class="btn btn-outline-secondary" [attr.aria-label]="'Add' | translate" (click)="addAnswerEntry(entries)">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        }
      </div>
    </div>
  </fieldset>
}