<div id="TipInfoBox" class="card card-default">
  <div class="card-header" (click)="toggleCollapse(); utilsService.stopPropagation($event)">
    <div>
      <span class="d-flex justify-content-between">
        <span>
          @if (wbTipService.tip.data.whistleblower_identity) {
            <span>{{'Identity'|translate}}</span>
          }
          @if (!wbTipService.tip.data.whistleblower_identity) {
            <span>{{wbTipService.tip.whistleblower_identity_field.label}}</span>
          }
          @if (wbTipService.tip.data.whistleblower_identity_provided) {
            <span class="btn btn-sm btn-link">
              @if (collapsed) {
                <span>{{'Show'|translate}}</span>
              }
              @if (!collapsed) {
                <span>{{'Hide'|translate}}</span>
              }
            </span>
          }
        </span>
        <span class="float-end">
          @if (!collapsed) {
            <i class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
          }
          @if (collapsed) {
            <i class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
          }
        </span>
      </span>
    </div>
  </div>
  @if (!collapsed) {
    <div class="card-body">
      @if (wbTipService.tip.data.whistleblower_identity) {
        <div>
          @for (field of wbTipService.tip.whistleblower_identity_field.children; track field; let index = $index) {
            <div>
              <src-tip-field [fields]=field [fieldAnswers]=wbTipService.tip.data.whistleblower_identity [index]="index"></src-tip-field>
            </div>
          }
        </div>
      }
      @if (wbTipService.tip.status !== 'closed' && !wbTipService.tip.data.whistleblower_identity) {
        <div>
          <form #wbform="ngForm" ngFormChanges (ngFormChange)="onFormChange()">
            @if (identity_provided) {
              <src-whistleblower-identity-field [step]="step" (notifyFileUpload)="notifyFileUpload.emit()" (stateChanged)="stateChanged($event)" [identity_provided]="identity_provided" [answers]="answers" [field]="field" [index]="0"></src-whistleblower-identity-field>
            }
            <src-rfiles-upload-status [progress]="uploadProgress" [estimatedTime]="uploadEstimateTime" [uploading]="isUploading"></src-rfiles-upload-status>
            <div id="SubmitBox">
              @if (identity_provided) {
                <button id="SubmitButton" class="btn btn-primary float-end" (click)="provideIdentityInformation.emit({ param1: field.id, param2: answers })" [disabled]="wbform.invalid" type="submit">
                  <i id="SubmitIconOK" class="fa-solid fa-check"></i>
                  <span>{{'Submit'|translate}}</span>
                </button>
              }
            </div>
          </form>
        </div>
      }
    </div>
  }
</div>