@if (authenticationData.session) {
  <div class="row mt-4">
    <div class="col-md-10" [ngClass]="{'container-disabled': !canUploadFiles()}">
      <div class="form-group">
        <div>
          @for (admin_file of admin_files; track admin_file) {
            <src-admin-file [adminFile]="admin_file"></src-admin-file>
          }
        </div>
        <div class="mb-2">
          <div>{{ 'Files' | translate }}</div>
          <div class="uploadfile file-custom">
            <span class="custom-file">
              <input #uploader id="customFile" type="file" [style.display]="'none'" class="custom-file-input inputelem" (change)="onFileSelected(uploader.files)" />
              <label class="btn btn-sm btn-primary" for="customFile">
                <i class="fa-solid fa-upload"></i>
                <span>{{ 'Upload' | translate }}</span>
              </label>
            </span>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped" id="fileList">
            <tbody id="fileListBody">
              @for (file of files | orderBy:'name'; track file) {
                <tr>
                  <td id="file_name">{{file.name}}</td>
                  <td>
                    <a class="btn btn-sm btn-primary" [href]="'s/' + file.name" [download]="file.name">
                      <i class="fa-solid fa-download"></i>
                      <span>{{ 'Download' | translate }}</span>
                    </a>
                    <button class="btn btn-sm btn-danger" (click)="deleteFile('api/admin/files/' + file.id)">
                      <i class="fa-solid fa-trash"></i>
                      <span id="delete">{{ 'Delete' | translate }}</span>
                    </button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-check form-switch">
        <app-switch name="authenticationData.session.permissions.can_upload_files_switch" [isChecked]="permissionStatus" [can_upload_files]="authenticationData.session.permissions.can_upload_files" [label]="authenticationData.session.permissions.can_upload_files ? 'Enabled' : 'Disabled'" (click)="togglePermissionUploadFiles()"></app-switch>
      </div>
    </div>
  </div>
}