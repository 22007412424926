@if (stepForm.invalid) {
  <div>
    <p>{{'In this step the answers to the following questions are either missing or invalid:'|translate}}</p>
    <ul>
      @for (error of getInnerGroupErrors(stepForm); track error) {
        <src-step-error-entry [form]="stepForm" [field_id_map]="field_id_map" [err]=error></src-step-error-entry>
      }
    </ul>
  </div>
}
