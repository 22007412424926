<div class="row">
  <div class="col-md-12">
    @if (!appDataService.public.node.simplified_login) {
      <label>{{'Enter your account\'s username or your email address to request a password reset.'|translate}}</label>
    }
    @if (appDataService.public.node.simplified_login) {
      <label>{{'Enter your email address to request a password reset.'|translate}}</label>
    }
  </div>
</div>
<br>
  <div class="row">
    <div class="col-md-3">
      <form name="form" autocomplete="off" novalidate>
        <div class="input-group">
          <input class="form-control" name="username" [(ngModel)]="username" type="text" autofocus required />
          <span class="input-group-append">
            <button class="btn btn-primary rounded-start-0 m-0" id="submit" (click)="submitRequest()" [disabled]="!username" type="submit">Submit</button>
            <a class="btn" (click)="utilsService.openSupportModal(appDataService)" data-toggle="tooltip" title="{{'Request support' | translate}}">
              <i class="fa-solid fa-life-ring"></i>
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>