<div class="modal-header">
  <div class="modal-title">{{ 'Reopen' | translate }}</div>
  <button type="button" class="close btn" (click)="cancel()" [attr.aria-label]="'Close' | translate">
    <i class="fa-solid fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <span>{{ 'Motivation' | translate }}</span>
  <span data-toggle="tooltip" title="{{'This field is mandatory' | translate}}" class="text-danger sup">*</span>
  <div class="form-group">
    <textarea name="motivation" class="form-control" [ngClass]="{'is-invalid': !arg.motivation}"
    [(ngModel)]="arg.motivation" required></textarea>
    @if (!arg.motivation) {
      <span class="text-danger">{{ 'This field is mandatory' | translate }}</span>
    }
  </div>
</div>
<div class="modal-footer">
  <button type="button" id="modal-action-ok" class="btn btn-primary" [disabled]="!arg.motivation"
    (click)="confirm(arg.motivation)">
    <i class="fa-solid fa-check"></i>
    <span>{{ 'Confirm' | translate }}</span>
  </button>
  <span id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
    <i class="fa-solid fa-times"></i>
    <span>{{ 'Cancel' | translate }}</span>
  </span>
</div>