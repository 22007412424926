@if (wbTipService.tip.rfiles.length>0) {
  <div id="AttachedWBFile" class="card card-default">
    <div class="card-header clearfix" (click)="toggleCollapse(); utilsService.stopPropagation($event);">
      <span>{{'Files attached by recipients'|translate}}</span>
      <span class="float-end">
        @if (!collapsed) {
          <i class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
        }
        @if (collapsed) {
          <i class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
        }
      </span>
    </div>
    @if (!collapsed) {
      <div class="card-body">
        @for (wbfile of wbTipService.tip.rfiles | orderBy: 'creation_date'; track wbfile) {
          <div>
            <div id="rfile-{{index}}">
              <src-wbfiles [ctx]="ctx" (dataToParent)="listenToWbfiles($event)" [receivers_by_id]="receivers_by_id" [wbFile]="wbfile"></src-wbfiles>
            </div>
          </div>
        }
      </div>
    }
  </div>
}