<div id="TipQuestionnaireAnswersBox" class="card card-default" [attr.aria-expanded]="collapsed">
  <div class="card-header d-flex justify-content-between" (click)="toggleCollapse(); utilsService.stopPropagation($event)">
    <span>{{'Questionnaire answers'|translate}}</span>
    <span class="">
      @if (!collapsed) {
        <i class="fa-solid fa-chevron-up" data-toggle="tooltip" title="{{'Collapse' | translate}}"></i>
      }
      @if (collapsed) {
        <i class="fa-solid fa-chevron-down" data-toggle="tooltip" title="{{'Expand' | translate}}"></i>
      }
    </span>
  </div>
  @if (!collapsed) {
    <div class="card-body">
      @for (questionnaire of tipService.tip.questionnaires; track questionnaire; let index = $index) {
        <div>
          @if (tipService.tip.questionnaires.length > 1) {
            <div>
              <span>{{'Questionnaire'|translate}}</span>
              <span>{{index + 1}}</span>
            </div>
          }
          @for (step of questionnaire.steps | orderBy:'order'; track step; let questionare_steps = $index) {
            <div>
              @if (questionnaire.steps.length > 1 && tipService.tip.context.show_steps_navigation_interface && step.enabled) {
                <dt id="step={{questionare_steps}}">
                  <span>{{'Step'|translate}}</span>
                  <span>{{questionare_steps + 1}}: {{step.label}}</span>
                </dt>
              }
              @if (step.enabled) {
                <div>
                  @for (field of step.children | orderBy:['y', 'x']; track field; let index_step = $index) {
                    <div>
                      <ng-container>
                        <src-tip-field [fieldAnswers]="questionnaire.answers" [redactOperationTitle]="redactOperationTitle" [redactMode]="redactMode" [fields]="field" [index]="index_step"></src-tip-field>
                      </ng-container>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>