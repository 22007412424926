@if (uploading) {
  <span>{{'Uploading files…'|translate}}</span>
}
@if (!uploading && progress==100) {
  <span>{{'Upload completed successfully!'|translate}}</span>
}
<div id="FileUpoloadsInfo">
  @if (estimatedTime && uploading) {
    <div>
      <span>{{'Estimated upload time:'|translate}}</span>
      @if (isFinite(estimatedTime)) {
        <span>{{estimatedTime}}</span>
      }
      <span>{{'seconds'|translate}}</span>
      @if (progress) {
        <div class="progress progress-striped;active">
          <div class="progress-bar" role="progressbar" [ngStyle]="{width: progress*105 + '%'}"></div>
        </div>
      }
    </div>
  }
</div><br>